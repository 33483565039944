/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.ts
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';

import Footer from 'components/Footer';
import Header from 'components/Header';
import Main from 'components/Main';

import styles from './Layout.module.scss';

interface Props {
  children?: React.ReactNode;
}

const Layout: React.FC = ({ children }: Props) => (
  <section className={styles.wrapper}>
    <Header />
    <Main>{children}</Main>
    <Footer />
  </section>
);

Layout.defaultProps = {
  children: null,
};

export default Layout;
