/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';
import moment from 'moment';

import Text from 'components/Text';
import Translate from 'components/Translate';
import Link from 'components/Link';
import { PasaLogo } from 'utils/assets';
import { urls } from 'utils';

import styles from './Footer.module.scss';

const year = moment().year();

// tslint:disable no-redundant-boolean
const Footer: React.FC = () => (
  <footer className={styles.wrapper}>
    <div className={styles.content}>
      <PasaLogo />
      <div className={styles.copyright}>
        <Text.Light>
          <Translate i18nKey="FOOTER.COPYRIGHT" interpolations={{ year }} />
        </Text.Light>
        {false && <Link.Light href={urls.MC_URL} text="FOOTER.PRIVACY_POLICY" />}
      </div>
    </div>
  </footer>
);
// tslint:enable no-redundant-boolean

export default Footer;
