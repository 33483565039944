/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: routes.ts
 *
 * @author: Panasonic, developer
 *
 */
import { routesUrls } from 'utils';
import HarleyDavidson from './HarleyDavidson';

export default {
  defaultRoute: routesUrls.harleyDavidson,
  harleyDavidson: {
    component: HarleyDavidson,
    path: routesUrls.harleyDavidson,
  },
};
