/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.ts
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';
import { Route } from 'react-router';
import { isPlainObject } from 'lodash';

export const mapRoutes = (routes: any) =>
  Object.values(routes)
    .filter(isPlainObject)
    .map((config: any) => <Route {...config} key={config.path} />);
