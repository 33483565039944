/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.ts
 *
 * @author: Panasonic, developer
 *
 */

import { IS_PROD } from '../env';

const currentEnv = 'test-4';
const mcBase = `https://${currentEnv}.oc-management-console.qa.oneconnect.net`;

export default {
  MC_URL: `${IS_PROD ? window.CONFIG && window.CONFIG.MC_URL : mcBase}/info/privacy-policy`,
};
