/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.ts
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';
import { get, find } from 'lodash';
import { withRouter, RouteComponentProps } from 'react-router';

import Translate from 'components/Translate';
import pageTitles from 'utils/constants/router/pageTitles';

import styles from './PageTitle.module.scss';

const PageTitle = ({ location }: RouteComponentProps): JSX.Element | null => {
  const title = find(pageTitles, (_, route) => get(location, 'pathname').startsWith(route));

  if (!title) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Translate i18nKey={title} />
    </div>
  );
};

export default withRouter(PageTitle);
