/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';

import styles from './Article.module.scss';
import Translate from '../Translate';
import Text from '../Text';

interface Props {
  title: string;
  children: React.ReactNode;
}

const Article = ({ title, children }: Props): JSX.Element => (
  <article className={styles.wrapper}>
    <Text.ArticleTitle className={styles.title}>
      <Translate i18nKey={title} />
    </Text.ArticleTitle>
    {children}
  </article>
);

export default Article;
