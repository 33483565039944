/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';

import styles from './Main.module.scss';

interface Props {
  children: React.ReactNode;
}

function Main({ children }: Props) {
  return <div className={styles.wrapper}>{children}</div>;
}

export default Main;
