/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';

import Text from 'components/Text';
import Translate from 'components/Translate';
import Article from 'components/Article';
import Link from 'components/Link';

// @ts-ignore
import pdf from 'utils/assets/2020 - Harley-Davidson Connect.pdf';
import styles from './HarleyDavidson.module.scss';

const I18_HD = 'LICENSING.HD';
const I18_OPEN_SOURCE_NOTICES = `${I18_HD}.OPEN_SOURCE_NOTICES`;
const I18_EXTERNAL_LINKS_DISCLAIMER = `${I18_HD}.EXTERNAL_LINKS_DISCLAIMER`;
const I18_HD_DOCUMENTATION = `${I18_HD}.HD_DOCUMENTATION`;
const I18_CONTACT_PANASONIC = `${I18_HD}.CONTACT_PANASONIC`;

const contactEmail = 'contact_us@panasonic.com';

const HarleyDavidson: React.FC = () => {
  return (
    <section className={styles.wrapper}>
      <section className={styles.content}>
        <Text.PageTitle className={styles.pageTitle}>
          <Translate i18nKey={`${I18_HD}.PAGE_TITLE`} />
        </Text.PageTitle>

        <Article title={`${I18_OPEN_SOURCE_NOTICES}.TITLE`}>
          <Text.Paragraph>
            <Translate i18nKey={`${I18_OPEN_SOURCE_NOTICES}.P1`} />
          </Text.Paragraph>
          <Text.Paragraph>
            <Translate i18nKey={`${I18_OPEN_SOURCE_NOTICES}.P2`} />
          </Text.Paragraph>
          <Text.Paragraph>
            <Translate i18nKey={`${I18_OPEN_SOURCE_NOTICES}.P3`} />
          </Text.Paragraph>
          <Text.Paragraph>
            <Translate i18nKey={`${I18_OPEN_SOURCE_NOTICES}.P4`} />
          </Text.Paragraph>
          <Text.Paragraph>
            <Translate i18nKey={`${I18_OPEN_SOURCE_NOTICES}.P5`} />
          </Text.Paragraph>
          <Text.Paragraph>
            <Translate i18nKey={`${I18_OPEN_SOURCE_NOTICES}.P6`} />
          </Text.Paragraph>
        </Article>

        <Article title={`${I18_EXTERNAL_LINKS_DISCLAIMER}.TITLE`}>
          <Text.Paragraph>
            <Translate i18nKey={`${I18_EXTERNAL_LINKS_DISCLAIMER}.P1`} />
          </Text.Paragraph>
          <Text.Paragraph>
            <Translate i18nKey={`${I18_EXTERNAL_LINKS_DISCLAIMER}.P2`} />
          </Text.Paragraph>
        </Article>

        <Article title={`${I18_HD_DOCUMENTATION}.TITLE`}>
          <Link href={pdf} text={`${I18_HD_DOCUMENTATION}.LINK`} className={styles.link} />
        </Article>

        <Article title={`${I18_CONTACT_PANASONIC}.TITLE`}>
          <Text.Paragraph className={styles.contactParagraph}>
            <Translate i18nKey={`${I18_CONTACT_PANASONIC}.P1`} />
          </Text.Paragraph>
          <Link href={`mailto:${contactEmail}`} text={contactEmail} className={styles.link} />
        </Article>
      </section>
    </section>
  );
};

export default HarleyDavidson;
