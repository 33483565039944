/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';
import classnames from 'classnames';

import Translate from 'components/Translate';

import styles from './Link.module.scss';

interface Props {
  href: string;
  target?: string;
  className?: string;
  text: string;
}

const Link = ({ href, target, className, text }: Props): JSX.Element => {
  const linkClass = classnames(styles.wrapper, className);

  return (
    <a href={href} target={target} className={linkClass}>
      <Translate i18nKey={text} />
    </a>
  );
};

Link.Light = (props: Props): JSX.Element => <Link {...props} className={styles.linkLight} />;

Link.defaultProps = {
  target: '_blank',
  className: '',
};

export default Link;
