/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';
import classnames from 'classnames';

import styles from './Text.module.scss';

const variantsMapping = {
  heading1: 'h1',
  heading2: 'h2',
  heading3: 'h3',
  heading4: 'h4',
  body1: 'span',
  body2: 'span',
  body3: 'span',
  paragraph: 'p',
};

interface Props {
  children: any;
  variant?:
    | 'heading1'
    | 'heading2'
    | 'heading3'
    | 'heading4'
    | 'body1'
    | 'body2'
    | 'body3'
    | 'paragraph';
  className?: string;
  component?: any;
}

const Text = ({ children, className, variant, component }: Props): JSX.Element => {
  const Cmp = component || variantsMapping[variant || 'body1'] || 'span';
  const classes = classnames(className, styles[variant || 'body1']);

  return <Cmp className={classes}>{children}</Cmp>;
};

Text.Light = (props: Props): JSX.Element => (
  <Text {...props} variant="body1" className={styles.light} />
);

Text.PageTitle = (props: Props): JSX.Element => <Text {...props} variant="heading1" />;

Text.ArticleTitle = (props: Props): JSX.Element => <Text {...props} variant="heading2" />;

Text.Paragraph = (props: Props): JSX.Element => <Text {...props} variant="paragraph" />;

Text.defaultProps = {
  className: '',
  variant: 'body1',
  component: null,
};

export default Text;
