import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';

import Layout from 'components/Layout';
import Views from 'views';
import i18n from 'utils/i18n';

const App: React.FC = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Suspense fallback={null}>
          <Layout>
            <Views />
          </Layout>
        </Suspense>
      </Router>
    </I18nextProvider>
  );
};

export default App;
