/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 *
 */

import { useTranslation } from 'react-i18next';

interface Props {
  i18nKey: string;
  interpolations?: StringMap;
}

const Translate = ({ i18nKey, interpolations }: Props): JSX.Element => {
  const { t } = useTranslation();

  return t(i18nKey, { ...interpolations });
};

Translate.defaultProps = {
  interpolations: {},
};

export default Translate;
