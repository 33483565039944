/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';

import PageTitle from 'components/PageTitle';
import { OnecLogo } from 'utils/assets';

import styles from './Header.module.scss';

const Header: React.FC = () => (
  <header className={styles.wrapper}>
    <div className={styles.content}>
      <OnecLogo />
      <PageTitle />
    </div>
  </header>
);

export default Header;
